import { kebabCase, snakeCase } from 'lodash-es';
export interface UiTableColumnOptions {
  expand?: boolean;
  tooltipId?: string;
  visible?: boolean;
  extraTdCssClasses?: string[];
}

export class UiTableColumn<T> {
  private _label: string;
  readonly kebabCasedLabel: string;
  readonly snakeCasedLabel: string;
  readonly snakeCasedModelName: string;
  readonly translationIdForLabel: string;
  readonly tooltipId: string;
  readonly thClasses: string[];
  readonly tdClasses: string[];
  visible: boolean;

  constructor(
    modelName: string,
    label: string,
    private getter: (row: T) => string | null,
    options: UiTableColumnOptions = {}
  ) {
    this._label = label;
    this.kebabCasedLabel = kebabCase(label);
    this.snakeCasedLabel = snakeCase(label);
    this.snakeCasedModelName = snakeCase(modelName);
    this.translationIdForLabel = `${this.snakeCasedModelName}s.columns.${this.snakeCasedLabel}`;
    // @ts-expect-error (legacy code incremental fix)
    this.tooltipId = options?.tooltipId;
    this.visible = options?.visible !== undefined ? options.visible : true;
    this.tdClasses = [
      `ui-td-${this.kebabCasedLabel}`,
      `x-td-${this.kebabCasedLabel}`,
      ...(options?.extraTdCssClasses || []),
    ];
    this.thClasses = options?.expand ? ['ds-datatable__col--max'] : [];
  }

  get className() {
    return this._label;
  }

  get label() {
    return this._label;
  }

  value(row: T): string {
    // @ts-expect-error (legacy code incremental fix)
    return this.getter(row);
  }
}
